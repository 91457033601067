import { defineStore } from "pinia";

/**
 * This store is used to programmatically scroll
 * to a section (fragments) on the home page 
 * 
 * This solution is used because vue-router doesn't automatically 
 * scroll to fragments
 */
const useNavigationStore = defineStore('navigation', {
    state: () => ({
        homeScrollTo: '', // The section intended to scroll to,
        headerScrollTo: '',
    }),
    getters: {
        getHomeScrollTo: (state) => state.homeScrollTo,
        getHeaderScrollTo: (state) => state.headerScrollTo
    },
    actions: {
        setHomeScrollTo(id: any) {
            this.homeScrollTo = id
        },
        setHeaderScrollTo(id: any) {
            this.headerScrollTo = id
        }
    }
})

export default useNavigationStore;